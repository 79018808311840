module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"de2351716feaba233286bafdb3a9b8d3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Mulish","file":"https://fonts.googleapis.com/css2?family=Mulish:wght@300;800&display=swap"},{"name":"Bebas Neue","file":"https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"},{"name":"Public Sans","file":"https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,600;1,400;1,600&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/giorgett/stampiz/code/stampiz.io/ssr/src/intl","languages":["it","en","de"],"defaultLanguage":"it","redirect":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
